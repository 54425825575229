<template>
  <b-container class="mt-4">
    <b-row>
      <b-col cols="2" class="text-center">
        <b-avatar
          :src="user.avatar_url"
          variant="info"
          size="6rem"
          class="mb-2"
        />
        <!-- src="https://www.shutterstock.com/image-photo/headshot-portrait-smiling-doctor-wearing-260nw-2002245095.jpg" -->
      </b-col>
      <b-col cols="10">
        <h1 class="c-f">{{ user.full_name }}
          <span
            v-if="user.is_demo_user"
            class="p-2"
            style="border-radius: 5px; font-size:1rem;background:#ffb900;color: white;"
          >demo</span>
        </h1>
        <!-- <p class="c-f">{{ user.about }}</p> -->
        <p class="c-f" v-html="user.about"></p>

        <b-button
          variant="outline-primary"
          class="mb-3"
        >Connect</b-button>


      </b-col>
    </b-row>
    <b-form-input
      v-if="initialCollectionsCount > 0"
      v-model="searchInput"
      debounce="500"
      class="d-inline-block mb-3"
      type="text"
      placeholder="Search profession, city, state"
      style="width:100%;">
    </b-form-input>

    <b-row v-if="collections.length > 0">
      <b-col
        v-for="col in collections"
        cols="12"
        md="6"
        v-bind:key="col.id"
        class="mb-3 collection-card box-shadow"
        @click="openModal(col)"
      >
        <b-card>
          <!-- <a :href="getLink(col)" style="font-size:0.75rem;" class="">{{  col.uuid  }}</a> -->
          <!-- <div class="my-3 py-3" style="border-bottom:1px solid gray;">Collection of referrals</div> -->
          <div class="input-label c-f">{{ col.name }}</div>
          <div class="input-label c-f">Price: ${{  col.price_in_dollars  }}</div>
          <p>{{ col.about_shortened }}</p>
          <div style="position: absolute; top: 3.5rem; right:1rem;">{{ col.purchased_count }} purchases</div>
          <div
            v-for="ref in col.referrals"
            v-bind:key="ref.id"
          >
            <referral-card :referral="ref" />
          </div>

          <div class="">
            <item-card
              v-for="item in col.items"
              v-bind:key="item.id"
              :item="item"
              :is-viewable="col.is_viewable"
            />
          </div>

        </b-card>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col cols="12">
        <b-card class="text-center">
          <div>{{ user.full_name }} has no collections, yet</div>
          <SmileySvg
            style="width:50px;color:yellow;height:auto;"
            color="rgb(109, 183, 237)"
            size="5rem"
            class="d-inline-block mr-1 mb-3"
          />
        </b-card>
      </b-col>
    </b-row>

    <b-modal id="edit-collection-modal" ref="collection-details-modal" :title="selectedCollectionName">
      <b-card>
        <p>This collection was clicked on {{ selectedCollection.viewed_count }} times and purchased {{ selectedCollection.purchased_count }} times - try lowering your price or providing more content</p>
        <p>Do you want to <a :href="editCollectionUrl">edit this collection?</a></p>
      </b-card>
    </b-modal>

    <b-modal id="collection-details-modal" ref="collection-details-modal" :title="selectedCollectionName">
      <b-card>
          <!-- <div class="input-label c-f">{{  selectedCollection.name  }}</div> -->
          <!-- <div class="input-label c-f">Price: ${{  selectedCollection.price_in_dollars  }}</div> -->
          <p>{{ selectedCollection.about_shortened }}</p>
          <div
            v-for="ref in selectedCollection.referrals"
            v-bind:key="ref.id"
          >
            <referral-card :referral="ref" />
          </div>
        </b-card>
    </b-modal>

    <!-- <b-modal id="email-modal" ref="email-modal" title="Hints"> -->
    <b-modal id="email-modal" ref="email-modal" :title="selectedCollectionName">
        <b-card class="mb-3">
          <div class="mb-1">This collection consists of:</div>
          <div class="mb-4" style="font-size:1.25rem;">{{  selectedCollection.referrals.map((e) => e.profession_name).join(", ") }}</div>
          <div class="my-2">It costs <span style="font-size:2rem;color:rgb(109, 183, 237);">${{  selectedCollectionPrice }}</span> to access</div>
        </b-card>

          <div
          v-if="!currentUser.authenticated"
          class="form-item mb-3"
          >
          <div>Enter email</div>
          <b-form-input
            v-model="visitorEmail"
            placeholder="phil@hellohints.com">
          </b-form-input>
        </div>
        <div
          v-if="showPaymentEmailErrorMessage"
          class="error-message my-3"
        >{{ paymentEmailErrorMessage }}</div>

        <div>
          <p>By clicking below to make this purchase, you agree to be bound by the <a target="_blank" href="https://hellohints.com/about#/purchaser-terms">Hints Purchaser Terms</a>. This is a one-time purchase. There is no subscription.</p>
        </div>
        <b-button
          v-if="!paymentIntentCreated"
          @click="createPaymentIntent()"
          variant="primary"
          class="mb-3"
        >I want to buy this!</b-button>

        <form id="payment-form">
          <div id="payment-element">
            <!-- Elements will create form elements here -->
          </div>
          <button
            v-if="paymentIntentCreated"
            id="submit"
            class="btn btn-primary mt-3"
            variant="primary"
          >Purchase on Hints
          </button>
          <div id="error-message">
            <!-- Display error message to your customers here -->
          </div>
        </form>

        <div
          v-if="modalMessage"
          style="font-size:1.5rem;"
          class="my-3"
        >
          {{ modalMessage }}
        </div>

        <template #modal-footer>
          <!-- <b-button size="sm" variant="success" @click="submitEmail()">
            Submit
          </b-button> -->
          <b-button size="sm" variant="outline-secondary" @click="nevermind()">
            Forget it
          </b-button>
          <!-- <b-button size="sm" variant="outline-secondary" @click="hide('forget')">
            Forget it
          </b-button> -->
        </template>
    </b-modal>

  </b-container>
</template>

<script>
import axios from 'axios';
import VueCookies from 'vue-cookies';
import ReferralCard from '../components/ReferralCard.vue';
import ItemCard from '../components/ItemCard.vue';
import { createAnalyticsEvent } from '../hooks/helperFunctions'
import { stripePublishableKey } from '../constants/keys'
import SmileySvg from '../../assets/icons/SmileySvg.vue'

export default {
  name: 'UserPage',
  components: {
    ReferralCard,
    ItemCard,
    SmileySvg
  },
  data() {
    return {
			csrfToken: '',

      initialCollectionsCount: 0,
      collections: [],
      collection: {},
      referrals: [],

      modalMessage: '',
      editCollectionUrl: '',
      selectedCollectionId: 0,
      selectedCollection: {},
      selectedCollectionName: '',
      selectedCollectionPrice: '',

      searchInput: '',
      user: {
        full_name: '',
        about: ""
      },

      // STRIPE
      visitorUuid: 0,
      visitorEmail: '',
      stripeUuid: '',
      paymentIntentCreated: false,
      orderNumber: '',
      showPaymentEmailErrorMessage: false,
      paymentEmailErrorMessage: 'Please enter your account email, or the email you want to receive the collections to',
      paymentClientSecret: '',

    }
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser
    },
  },
	mounted() {



	},
  created() {
    this.csrfToken = VueCookies.get('csrftoken')
    this.getUser(`${process.env.VUE_APP_HOST}/api/get_user/?user=${this.$route.params.uuid}`)

    // const email = this.$route.query.email
    this.getCollections(`${process.env.VUE_APP_HOST}/api/collections/?user=${this.$route.params.uuid}`, true)
  },

  watch: {
		searchInput: {
			handler() {
				if (this.searchInput == '') {
          this.getCollections(`${process.env.VUE_APP_HOST}/api/collections/?user=${this.$route.params.uuid}`)
				} else {
          this.getCollections(`${process.env.VUE_APP_HOST}/api/collections/?user=${this.$route.params.uuid}&search=${this.searchInput}`)
				}
			},
		},
  },

  methods: {
    getUser(url) {
      axios.get(url)
      .then(response => {
        console.log('getting user..')
        console.log(response.data)
				this.user = response.data
      })
      .catch(e => {
				console.log(e)
        this.errors.push(e)
      })
    },

    getCollections(url, init=false) {
      axios.get(url)
      .then(response => {
        // console.log("logging collections!")
        // console.log(response.data)
				this.collections = response.data.results
        if (init) {
          this.initialCollectionsCount = response.data.count
        }
      })
      .catch(e => {
				console.log(e)
        this.errors.push(e)
      })
    },

    createPermissionOrCheckEmail(url){
      axios.get(url)
      .then(response => {
				// this.collections = response.data.results
        // console.log('response..')
        // console.log(response)
        if (response.data.permitted) {
          // setTimeout(() => {  this.$bvModal.hide("email-modal") }, 1000);
          this.modalMessage = response.data.message
          setTimeout(() => {
            this.getCollections(`${process.env.VUE_APP_HOST}/api/collections/?user=${this.$route.params.uuid}&email=${this.email}`)
            this.$bvModal.hide("email-modal") }
          , 1500);

        } else {
          this.modalMessage = response.data.message
        }
      })
      .catch(e => {
				console.log(e)
        this.errors.push(e)
      })
    },

    // submitEmail() {
      // this.createPermissionOrCheckEmail(`${process.env.VUE_APP_HOST}/api/create_permission_or_check_email/?email=${this.email}&collection_id=${this.selectedCollectionId}`)
      // this.createPermissionOrCheckEmail(`${process.env.VUE_APP_HOST}/api/create_permission_or_check_email/?email=${this.email}&collection_id=${this.selectedCollectionId}&stripe_uuid=1235`)
    // },

    openModal(collection) {
      this.selectedCollection = collection
      this.selectedCollectionName = collection.name
      this.selectedCollectionPrice = collection.price_in_dollars
      this.selectedCollectionId = collection.id
      this.editCollectionUrl = process.env.VUE_APP_HOST + '/#/collections/' + collection.uuid + '/edit'

      if (this.currentUser.uuid == collection.author.uuid) {
        this.$bvModal.show("edit-collection-modal")
      } else if (collection.is_viewable) {
        this.$bvModal.show("collection-details-modal")
      } else {
        createAnalyticsEvent('viewed-collection', 'collection', collection.uuid)
        this.$bvModal.show("email-modal")
      }
    },

    nevermind() {
      console.log('nevermind')
      this.$bvModal.hide("email-modal")
      this.visitorEmail = ''
    },

    async createPaymentIntent() {
      if (this.currentUser.authenticated) {
        this.visitorEmail = this.currentUser.email
        this.visitorUuid = this.currentUser.uuid
      }

      if (!this.user.payment_setup_complete) {
        console.log('ehh1 ?')
        this.paymentEmailErrorMessage = 'This user does not have their bank account set up. We have emailed them to let them know you want access!'
        this.showPaymentEmailErrorMessage = true

      } else if (this.visitorEmail == '') {
        console.log('ehh2 ?')
        this.paymentEmailErrorMessage = 'Please enter your account email, or the email you want to receive the collections to',
        this.showPaymentEmailErrorMessage = true


      } else {
        console.log('ehh3 ?')
        this.paymentIntentCreated = true

        console.log(this.selectedCollection)
        console.log(this.selectedCollectionPrice)

        const paymentTokenUuid = crypto.randomUUID()
        console.log(paymentTokenUuid)

        // TODO ASAP!! set this to datetime here - send it to my backend -
        // when i look for permittedView - double check the payment token
        VueCookies.set('paymentSuccesstoken', paymentTokenUuid) //

        // IMPORTANT:
        // Caution
        // If you have tooling that tracks the customer’s browser session, you might need to add the stripe.com domain to the referrer exclude list. Redirects cause some tools to create new sessions, which prevents you from tracking the complete session.
        const payload = {
          visitor_uuid: this.visitorUuid,
          visitor_email: this.visitorEmail,
          selected_collection: this.selectedCollection,
          browser_payment_token_uuid: paymentTokenUuid,
        }
        const res = await axios.post(`${process.env.VUE_APP_HOST}/api/create_stripe_payment_intent/`, payload, {headers: { "content-type": "application/json", 'X-CSRFToken': this.csrfToken}})
        // console.log('heyoo! PAYMENT INTENT')
        // console.log(res)
        this.paymentClientSecret = res.data.client_secret
        this.orderNumber = res.data.payment_intent_order_number

        this.renderStripePaymentForm()
        // const body = res.data
        // window.location.href = body.url

      }
    },

    renderStripePaymentForm() {
      console.log('rendering stripe payment 1')
      // might need to do this.. from here:
      // https://stripe.com/docs/connect/enable-payment-acceptance-guide?platform=web&ui=payment-element&client=html#accept-payment

      // Initialize Stripe.js with the same connected account ID used when creating
      // the PaymentIntent.
      // const stripe = Stripe(''', {
      //   stripeAccount: '{{CONNECTED_ACCOUNT_ID}}'
      // });

      // Can be publicly accessible in your web or mobile app’s client-side code
      // https://stripe.com/docs/keys
      const publishableKey = stripePublishableKey
      const stripe = window.Stripe(publishableKey);
      // console.log('rendering payment form! using key', publishableKey)


      let options = {
        clientSecret: this.paymentClientSecret,
        // appearance: appearance,   // Fully customizable with appearance API.
      };

      // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 3
      let elements = stripe.elements(options);

      // Create and mount the Payment Element
      let paymentElement = elements.create('payment');
      paymentElement.mount('#payment-element');

      // Render Payment form
      const form = document.getElementById('payment-form');
      console.log('rendering stripe payment 4')

      form.addEventListener('submit', async (event) => {
        event.preventDefault();
        const {error} = await stripe.confirmPayment({
          //`Elements` instance that was used to create the Payment Element
          elements,
          confirmParams: {
            return_url: `${process.env.VUE_APP_HOST}/#/order/${this.orderNumber}/complete`
          },
        });

        if (error) {
          // This point will only be reached if there is an immediate error when
          // confirming the payment. Show error to your customer (for example, payment
          // details incomplete)
          const messageContainer = document.querySelector('#error-message');
          messageContainer.textContent = error.message;
        } else {

          // Your customer will be redirected to your `return_url`. For some payment
          // methods like iDEAL, your customer will be redirected to an intermediate
          // site first to authorize the payment, then redirected to the `return_url`.
        }
      });

      // get_or_create_permitted_view
      // this must be someone "logged out"- goes to UserApp vs MarketingApp...

    },

    getLink(col) {
      return process.env.VUE_APP_HOST + '#/users/' + this.$route.params.uuid + '/collections/' + col.uuid
    }
  }
}
</script>

<style lang="scss" scoped>

.referral-card {
  border-radius: 5px;
  // background:lightgreen;
}

.collection-card {
  cursor: pointer;
}

.box-shadow {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.bold {
  font-weight: 700;
}

.input-label {
  font-size: 1.5rem;
  // font-weight: 600;
}

.c-f {
  font-family: Larsseit;
}

.profession-name {
  font-size:1.25rem;
}

.collection-status {
  color: white;
  border-radius: 5px;
  width: fit-content;
  padding: 5px;
  position: absolute;
  top: 1rem;
  right: 1rem;

  &.under-review {
    border: 1px solid gold;
    background: gold;
  }
  &.live {
    background: lightseagreen;
    border: 1px solid lightseagreen;
  }

  &.inactive {
    background: lightcoral;
    border: 1px solid lightcoral;
  }
}

.error-message {
  color: #e44436;
  font-size: 0.85rem;
  margin-top:0.5rem;
  margin-bottom:0.5rem;
}

.box-shadow {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

</style>
