<template>
  <div>
    <terms />
  </div>
</template>

<script>
import Terms from './Terms.vue';

export default {
  name: 'TermsPage',
  components: {
    Terms
  },
  data() {
    return {
    }
  },
  computed: {

  },
	mounted() {
		window.scrollTo(0, 0)
  },
  created() {
    // this.getCollectionViews(`${process.env.VUE_APP_HOST}/api/users/${this.$route.params.uuid}/`)
    // this.getViews(`${process.env.VUE_APP_HOST}/api/users/${this.currentUser.uuid}/views/`)
  },

}
</script>

<style lang="scss" scoped>


</style>
