<template>
  <b-container class="mt-4">
    <h1 class="mb-5">Order Success Page</h1>

    <b-row>
      <b-col md="8" cols="12">
        <b-card>
          <h4>Now what?!</h4>
          <div>This collection and receipt have been emailed to you.</div>
          <div>Create an account to view all your collections and <span class="highlight">create and share your own</span>!</div>
          <br>
          <div>Signup Link: <a :href="permittedView.registration_invitation_link">{{ permittedView.registration_invitation_link }}</a></div>
          <!-- <div>Show the specific collection here on this page - but then they can send this link to anyone..</div> -->
        </b-card>
      </b-col>

      <b-col md="4" cols="12">
        <b-card class="mb-5">
          <h5>Order Details:</h5>
          <div id="message"></div>
          <div>Purchased on: {{ permittedView.created_at }} </div>
        </b-card>
      </b-col>

      <!-- <div> -->
        <!-- {{collection}} -->
      <!-- </div> -->
      <b-col cols="3" />
      <b-col cols="6">

        <collection-card :collection="collection" />
      </b-col>
      <b-col cols="3" />
      <!-- <h3 v-if="collection.author">{{ collection.author.full_name }}</h3>
      <b-col
        v-for="ref in collection.referrals"
        v-bind:key="ref.id"
        md="6" cols="12
      ">
        <div
        >
          <referral-card
            :referral="ref"
          />
        </div>
      </b-col> -->

    </b-row>
  </b-container>
</template>

<script>
import axios from 'axios';
import VueCookies from 'vue-cookies';
import CollectionCard from '../components/CollectionCard.vue'
import { stripePublishableKey } from '../constants/keys'

// import EarningsSection from '../../components/EarningsSection.vue'

export default {
  name: 'OrderSuccessPage',
  components: {
    CollectionCard,
  },
  data() {
    return {
			csrfToken: '',

      collection: {},
      permittedView: {},

      // it really looks like this was the real redirect? what happened:?
      // http://localhost:8000/?payment_intent=pi_3MymyMDb1IBq8XCC3v3GyS0p&payment_intent_client_secret=pi_3MymyMDb1IBq8XCC3v3GyS0p_secret_iqCGnox544EhRxVcVC4AZDLtI&redirect_status=succeeded#/order/1245/complete

      // http://localhost:8000/?payment_intent=pi_3MymyMDb1IBq8XCC3v3GyS0p&payment_intent_client_secret=pi_3MymyMDb1IBq8XCC3v3GyS0p_secret_iqCGnox544EhRxVcVC4AZDLtI&redirect_status=succeeded#/order/1245/complete
      // http://localhost:8000/?payment_intent=pi_3MymyMDb1IBq8XCC3v3GyS0p&payment_intent_client_secret=pi_3MymyMDb1IBq8XCC3v3GyS0p_secret_iqCGnox544EhRxVcVC4AZDLtI&redirect_status=succeeded#/order/1245/complete


      // got very confused after a bunch of this shit happened..
      // http://localhost:8000/#/order/1245/complete?payment_intent=pi_3MymyMDb1IBq8XCC3v3GyS0p&payment_intent_client_secret=pi_3MymyMDb1IBq8XCC3v3GyS0p_secret_iqCGnox544EhRxVcVC4AZDLtI&redirect_status=succeeded
    }
  },
  computed: {
    // currentUser() {
    //   return this.$store.state.currentUser
    // },
  },
	mounted() {

  },
  created() {
    this.csrfToken = VueCookies.get('csrftoken')
    // Initialize Stripe.js using your publishable key
    // Can be publicly accessible in your web or mobile app’s client-side code
    // https://stripe.com/docs/keys
    const publishableKey = stripePublishableKey
    const stripe = window.Stripe(publishableKey);

    // Retrieve the "payment_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    );


    // Retrieve the PaymentIntent
    stripe.retrievePaymentIntent(clientSecret).then(({paymentIntent}) => {
    const message = document.querySelector('#message')
    console.log('paymentIntent')
    console.log(paymentIntent)

    // Inspect the PaymentIntent `status` to indicate the status of the payment
    // to your customer.
    //
    // Some payment methods will [immediately succeed or fail][0] upon
    // confirmation, while others will first enter a `processing` state.
    //
    // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification

    // THIS PAGE SHOULD
    // check for payment intent succeeded
    // get a permitted view object --
    // if it exists, get the collection
    switch (paymentIntent.status) {
      case 'succeeded':
        console.log('payment succeeded!!')
        // TODO is this where we fire off the API call to mark permitted view accepted
        // and to send the email with the information?
        message.innerText = 'Success! Payment received.';
        // http://localhost:8000/api/collections/b07xx79c55-4ab2-45da-af67-377d90b00e7a/
        // this.getOrCreatePermittedView(paymentIntent["id"], 1)
        this.getPermittedView(paymentIntent["id"])
        break;

      case 'processing':
        console.log('payment processing!')
        message.innerText = "Payment processing. We'll update you when payment is received.";
        break;

      case 'requires_payment_method':
          console.log('payment failed..hmm..')
          message.innerText = 'Payment failed. Please try another payment method.';
        // Redirect your user back to your payment page to attempt collecting
        // payment again
        break;

      default:
          console.log('seomthing wen tworng..')
        message.innerText = 'Something went wrong.';
        break;
      }
    });

  },
  watch: {

  },

  methods: {
    async getPermittedView(paymentIntentId) {
      const payload = {
        payment_intent_id: paymentIntentId,
        // status: status
      }
      const paymentSuccessToken = VueCookies.get('paymentSuccesstoken')
      await setTimeout(1000)

      await axios.post(`${process.env.VUE_APP_HOST}/api/get_permitted_view/`, payload, {headers: { "content-type": "application/json", 'X-CSRFToken': this.csrfToken}} )
      .then(response => {
        this.permittedView = response.data
        console.log('logging this permittedView!! ~~~ ')
        console.log(this.permittedView)
        const uuid = this.permittedView.collection.uuid
        if (this.permittedView.browser_payment_token_uuid == paymentSuccessToken) {
          console.log('browser permitted view and token are a MATCH')
          this.getCollection(`${process.env.VUE_APP_HOST}/api/collections/${uuid}/`)
        }
      })
      .catch(e => {
          this.errors.push(e)
      })
    },


    getCollection(url) {
      axios.get(url)
      .then(response => {
        console.log('logging response for collection!')
        console.log(response.data)
        this.collection = response.data
      })
      .catch(e => {
				console.log(e)
        this.errors.push(e)
      })
    }

  }
}
</script>

<style lang="scss" scoped>

span {
  font-weight: 400;
  font-family: Larsseit-Thin;
  // thin is thinner than light
  // font-family: Larsseit-Light;
}

.card {
  border-radius: 0.75rem;
}

.large {
  font-size: 1.5rem;
}

div, span {
  font-family: Larsseit-Light;
}

</style>
