<template>
  <div>
    <h3>Privacy Policy for Hints LLC</h3>

    <p>At Hints LLC ("Hints"), we respect your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and disclose your personal information in connection with our platform. By accessing or using Hints, you agree to the terms of this Privacy Policy.</p>

    <h5>Information Collection</h5>
    <p>Hints collects personal information from users in order to administer its platform. This information may include:</p>
    <ul>
      <li>Contact information, such as your name, email address, and phone number</li>
      <li>Payment information, such as your credit card number and billing address</li>
      <li>Information related to the content you publish on the platform, including any personal information contained within that content</li>
      <li>Technical information, such as your IP address, browser type, and operating system</li>
      <li>Information about your use of the platform, such as the pages you visit and the actions you take</li>
    </ul>

    <h5>Use of Information</h5>
    <p>
      Hints uses your personal information to administer its platform and to provide the services you request. This includes:
    </p>
    <ul>
      <li>Processing payments for content published on the platform</li>
      <li>Verifying your identity and preventing fraud</li>
      <li>Communicating with you about your account and the platform</li>
      <li>Analyzing user behavior and trends to improve the platform</li>
    </ul>

    <p>
      Hints may also use your personal information to send you promotional emails or other marketing materials. You may opt-out of receiving these communications at any time.
    </p>

    <h5>Disclosure of Information</h5>
    <p>Hints may disclose your personal information to third-party service providers who assist us in providing our services, such as payment processing services. We may also disclose your personal information to comply with legal requirements, such as responding to a subpoena or court order.</p>

    <p>If Hints is acquired by or merged with another company, your personal information may be transferred to the new owners.</p>

    <h5>Data Retention</h5>
    <p>Hints retains your personal information for as long as necessary to provide our services and to comply with legal requirements. We may also retain certain information for analysis or record-keeping purposes.</p>

    <h5>Security</h5>
    <p>Hints takes reasonable measures to protect your personal information from unauthorized access, disclosure, or use. We use industry-standard encryption and other security technologies to protect your personal information during transmission and storage.</p>

    <h5>Third-Party Links</h5>
    <p>Hints may provide links to third-party websites or services on its platform. These websites or services may have their own privacy policies, which you should review before using them. Hints is not responsible for the privacy practices of these third-party websites or services.</p>

    <h5>Children's Privacy</h5>
    <p>Hints is not intended for use by children under the age of 13. We do not knowingly collect personal information from children under the age of 13.</p>

    <h5>Changes to this Privacy Policy</h5>
    <p>Hints reserves the right to amend this Privacy Policy at any time without prior notice. Any changes will be posted on the Hints website or mobile application. Your continued use of Hints after any changes have been made constitutes your acceptance of the new Privacy Policy.</p>

    <h5>Contact Us</h5>
    <p>If you have any questions or concerns about this Privacy Policy or Hints' privacy practices, please contact us at [contact email].</p>

  </div>
</template>


<script>

export default {
  name: 'PrivacyPolicy',
  components: {

  },
  data() {
    return {
    }
  },
  computed: {

  },
	mounted() {

  },
  created() {
    // this.getCollectionViews(`${process.env.VUE_APP_HOST}/api/users/${this.$route.params.uuid}/`)
    // this.getViews(`${process.env.VUE_APP_HOST}/api/users/${this.currentUser.uuid}/views/`)
  },

}
</script>

<style lang="scss" scoped>


</style>
