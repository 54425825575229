<template>
  <div class="">
    <b-form-input v-model="supportRequestEmail" class="mb-3" placeholder="Your email"/>
    <b-form-textarea v-model="supportRequestBody" class="mb-3"  style="resize:none;" placeholder="Why you'd like to join"/>
    <div v-if="errorMessage" style="color:lightsalmon;" class="my-2">
      {{ errorMessage }}
    </div>
    <div v-if="alertMessage" style="color:lightseagreen;" class="my-2">
      {{ alertMessage }}
    </div>
    <b-button variant="primary" @click="submitSupportRequest()">Submit</b-button>
  </div>
</template>

<script>
import axios from 'axios';
// import VueCookies from 'vue-cookies'

export default {
  name: 'ContactFormAnonymous',
  components: {

  },
  props: [

  ],
  data() {
    return {
      errorMessage: '',
      alertMessage: '',
      supportRequestEmail: '',
      supportRequestBody: '',
    }
  },
  computed: {
    // currentUser() {
      // return this.$store.state.currentUser
    // },
  },
	mounted() {

	},
  created() {

  },
  watch: {
    // optionsSearch: _.debounce(function(addr) { this.getOptions(addr) }, 500), // worked before..
  },

  methods: {
    validateEmail(input) {
      // all of these need to be true for this to return true
      return (input != "" && input.includes("@") && input.includes("."))
    },

    submitSupportRequest() {
      if (!this.validateEmail(this.supportRequestEmail) || this.supportRequestBody == '') {
        this.alertMessage = ''
        this.errorMessage = "Please enter your email and tell us why you'd like to join"
        return
      }
      const payload = {
        email: this.supportRequestEmail,
        body: this.supportRequestBody,
      }
      this.supportRequestBody = ''
      axios.post(`${process.env.VUE_APP_HOST}/api/create_support_request/`, payload, {headers: { "content-type": "application/json"}} )
      .then(response => {
        if (response.data.status == "OK") {
          this.dismissCountDown = this.dismissSecs
          this.supportRequestEmail = ''
          this.supportRequestBody = ''
          this.errorMessage = ''
          this.alertMessage = 'Request submitted! You should hear back within a day or two.'
          // this.dismissCountDown = this.dismissSecs
        } else {
          this.dismissCountDown = this.dismissSecs
          this.errorMessage = 'Something went wrong..'
        }
      })
      .catch(e => {
        this.errors.push(e)
        this.dismissCountDown = this.dismissSecs
        this.errorMessage = 'Something went wrong..'
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
